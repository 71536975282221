<template>
  <div class="content-container">
    <PageTitle :title="$t('MISSIONS.TITLE')" class="page-title">
      <template #extra-content>
        <button
          type="button"
          class="pink-button create-button"
          @click="createMission"
        >
          {{ $t('MISSIONS.CREATE') }}
        </button>
      </template>
    </PageTitle>
    <h1 class="page-section-title section-title">{{ $t('MISSIONS.OVERVIEW') }}</h1>

    <CustomTable :options="missionsTableOptions"
                 :table-data="tableData"
                 :pagination-data="paginationData"
                 :search-function="manageSearch"
                 :filter-data="filterData"
                 :empty="empty"
                 :empty-state-data="emptyStateData"
                 :no-results-data="noResultsData">
      <template #title="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #category="props">
        <div>{{ props.rowData.missionCategoryName }}</div>
      </template>
      <template #slides="props">
        <div>{{ props.rowData.slideCount }}</div>
      </template>
      <template #createdAt="props">
        <div>{{ new Date(props.rowData.createdAt).toLocaleDateString('nl') + ' ' + new Date(props.rowData.createdAt).toLocaleTimeString('nl') }}</div>
      </template>
      <template #status="props">
        <div>{{ !props.rowData.isActivated ? $t('CONCEPT') : $t('PUBLISHED') }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <OptionsButton
            :options="options"
            :row-data="props.rowData"/>
        </div>
      </template>
      <template #right-side-info>
        <p><span style="font-weight: 500;">{{ totalItems }}</span> {{ $t('MISSIONS.TOTAL_MISSIONS') }}</p>
      </template>
    </CustomTable>
    <BasicModal v-if="isBasicVersionDisplayed">
    </BasicModal>
  </div>

</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'

import CustomTable from '@/components/table/CustomTable'
import { getTableOptions, MISSIONS } from '@/utils/helpers/customTable/CustomTableHelper'
import { createEmptyTableData, createNoResultsData } from '@/utils/helpers/customTable/EmptyTableHelper'
import { computed, ref } from '@vue/reactivity'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import { useStore } from 'vuex'
import { DELETE_MISSION } from '@/store/modules/cms/actions.js'
import { GET_MISSION_CATEGORIES, GET_MISSIONS } from '@/store/modules/cms/missions/actions.js'
import OptionsButton from '@/components/partials/missions/OptionsButton.vue'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { useRouter } from 'vue-router'

import ApiHandler from '../../../utils/handlers/ApiHandler'
import { useI18n } from 'vue-i18n'


export default {
  name: 'Missions',
  components: {
    PageTitle,
    CustomTable,
    OptionsButton,
    BasicModal,
  },
  setup() {
    const { t } = useI18n({})

    const modalController = ref(new ModalController())
    const store = useStore()
    const router = useRouter()

    const activeModal = computed(() => store.getters.getActiveBasicModal)

    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.DELETE_MISSION
    )


    const searchResults = computed(() => store.getters.getMissions)


    function searchMissions(payload) {
      return store.dispatch(GET_MISSIONS, payload)
    }

    function manageSearch(payload) {
      if (!payload.page) {
        payload.page = 1
      }
      searchMissions(payload)
    }

    const options =
      [
        {
          name: t('MISSIONS.EDIT'),
          action: editMission,
          icon: require('../../../assets/icons/icn_edit.svg'),
        },
        {
          name: t('MISSIONS.DUPLICATE'),
          action: duplicateMission,
          icon: require('../../../assets/icons/icn_duplicate.svg'),
        },
        {
          name: t('MISSIONS.DELETE'),
          action: deleteMission,
          icon: require('../../../assets/icons/icn_delete.svg'),
          red: true,
        },
      ]


    const totalItems = computed(() => searchResults.value.totalItems)

    const empty = computed(() => searchResults.value.totalItems === 0)

    const emptyStateData = createEmptyTableData('MISSIONS', createMission, require('@/assets/icons/icn_mission.svg'))

    const noResultsData = createNoResultsData('MISSIONS', require('@/assets/icons/icn_mission.svg'))

    const missionCategories = computed(() => store.getters.getMissionCategories)
    store.dispatch(GET_MISSION_CATEGORIES)


    const tableData = computed(() => searchResults.value.items)
    //This should be got from the api as well in the future.
    const filterData = computed(() => {
      return {
        placeholder: t('RANKING_LIST.CATEGORY_FILTER_PLACEHOLDER'),
        singleSelection: false,
        dropdownOptions: [
          {
            name: t('MISSION_DETAILS.CATEGORY'),
            type: 'missionCategoryId',
            options: missionCategories.value.map(cat => {
              cat.keyName = cat.name
              return cat
            })
          },
          {
            name: t('MEDIAQUIZ_CREATE.STATUS'),
            type: 'isActivated',
            singleSelection: true,
            options: [{ id: 0, name: t('CONCEPT') }, { id: 1, name: t('PUBLISHED') }]
          }
        ]
      }
    })


    const paginationData = computed(() => {
      return {
        totalPages: searchResults.value.totalPages,
        currentPage: searchResults.value.currentPage
      }
    })


    function duplicateMission(mission) {
      ApiHandler.post('cms/mission/duplicate-mission/' + mission.id).then(response => {
        console.log(response.error)
        if (response && response.status === 201) {
          manageSearch()
        }
      })
    }

    function deleteMission(mission) {
      modalController.value.setModal(BASIC_MODAL_CONTENT.DELETE_MISSION(mission.name), () => postDeleteMission(mission.id), null, null, MODAL_NAMES.DELETE_MISSION)
    }

    function postDeleteMission(missionId) {
      store.dispatch(DELETE_MISSION, missionId).then(() => {
        modalController.value.resetActiveModal()
        manageSearch({})
      })
    }

    function editMission(mission) {
      router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS, params: { id: mission.id } })
    }

    function createMission() {
      router.push({ name: ROUTE_NAMES_CMS.CREATE_MISSION })
    }


    return {
      missionsTableOptions: getTableOptions(MISSIONS),
      tableData,
      paginationData,
      filterData,
      totalItems,
      manageSearch,
      deleteMission,
      editMission,
      empty,
      emptyStateData,
      noResultsData,
      ROUTE_NAMES_CMS,
      isBasicVersionDisplayed,
      createMission,
      options,
    }
  }
}
</script>

<!--<style lang="scss" scoped>-->
<!--@import "~@/assets/css/base.variables";-->
<!--@import "~@/assets/css/base.mixins";-->


<!--</style>-->
